.spp {
    margin-top: 0px;
    background: #fff;
    display: block;
    float: left;
    width: 100%;
    position: relative;
}

.btn1{
    color: #111;
    background: #f5f5f5;
    font-size: 13px;
    line-height: 1.33333;
    height: 2.2rem;
    padding: 0 1.5rem;
    float: left;
    line-height: 2.3rem;
    font-weight: bold;
    margin-right: 10px;
    border-radius: 30px;
    cursor: pointer;
    display: inline-flex;
    border: 2px solid #f5f5f5;
    min-width: 42px;
    text-align: center;
}
.btn1.sel{
    border: 2px solid #de2333;
}


.ty-product-filters ul li, .ty-product-filters li {
    list-style-type: none!important;
}
.cm-product-filters {
display: block;
    display: flex;
    float: left;
    margin-bottom: 17px;
    margin-top: -30px;
    overflow: hidden;
    width: 100%;
      
}
@media(max-width:768px){
.cm-product-filters {
    display: flex;
    float: left;
    margin-bottom: 7px;
    margin-top: -4px;
    min-height: 50px;
    overflow: hidden;
    overflow-x: hidden;
    overflow-x: scroll;
    width: 100%;
}
}
li.cm-product-filters-checkbox-container.ty-product-filters__group {

    float: left;
    margin: 6px;
    
}
li.cm-product-filters-checkbox-container.ty-product-filters__group label{
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    border: 2px solid;
    float: left;
    font-weight: 700;
        border-radius: 50%;
}
li.cm-product-filters-checkbox-container.ty-product-filters__group label.checked {
    border-color: #de2333;
    background: #de2333;
    color:#fff;
}
li.cm-product-filters-checkbox-container.ty-product-filters__group label.disabled{
    opacity:0.4;
}
.menu-mask1 {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    position: fixed;
    z-index: 19;
}
@media(min-width:768px){
.cm-product-filters {
      overflow-y: initial;
  
}
.menu-mask2 {
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    position: fixed;
    z-index: 19;
}
.smarimi-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}
.sactive .menu-mask2 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}
.sculori-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}
.smaterial-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}
.ty-product-filters__color-filter {
    overflow-x: initial!important;
}
.sstoc-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
}
.plp-sheets {
    position: relative;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    overflow: hidden;
    border-radius: 0;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease, opacity 0.2s ease;
}
.plp-sheets1 {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 22%;
    background: #fff;
    z-index: 999;
    overflow: hidden;
    border-radius: 0;
    height: 100%;
    transform: translateX(100%);
    transition: transform 0.5s ease, opacity 0.2s ease;
}
@media(max-height:920px) and (min-width:1024px){
    .plp-sheets1 {
         overflow-y: scroll;
    }
}
.cm-product-filters.sactive .plp-sheets1 {
    transform: translateX(0%);
}
.sactive .plp-sheets1 .plp-sheets {
    transform: translateX(0%);
}
.smarimi-active .plp-sheets {
    transform: translateX(0%);
}
.sculori-active .plp-sheets {
    transform: translateX(0%);
}
.smaterial-active .plp-sheets {
    transform: translateX(0%);
}
.sstoc-active .plp-sheets {
    transform: translateX(0%);
}
.sstoc2-active .plp-sheets {
    transform: translateX(0%);
}
.sspret-active .plp-sheets {
    transform: translateX(0%);
}
.ty-product-filters__block ul#ranges_ {
    float: left;
    height: 100%;
    overflow-x: inherit;
    padding-left: 10px;
    padding-bottom: 20px;
}
}
@media(max-width:768px){
.plp-sheets {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #fff;
    z-index: 100;
    overflow: hidden;
    border-radius: 8px 8px 0px 0px;
    height: 40%;
    transform: translateY(100%);
    transition: transform 0.5s ease, opacity 0.2s ease;
}
.smarimi-active .plp-sheets {
    transform: translateY(0%);
}
.sculori-active .plp-sheets {
    transform: translateY(0%);
}
.smaterial-active .plp-sheets {
    transform: translateY(0%);
}
.sstoc-active .plp-sheets {
    transform: translateY(0%);
}
.sstoc2-active .plp-sheets {
    transform: translateY(0%);
}
.sspret-active .plp-sheets {
    transform: translateY(0%);
}
.smarimi-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 56px;
    left: 0;
}
.sculori-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 56px;
    left: 0;
}
.smaterial-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 56px;
    left: 0;
}

.sstoc-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 56px;
    left: 0;
}
.sstoc2-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 56px;
    left: 0;
}
.sspret-active .menu-mask1 {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100vh;
    width: 100vw;
    top: 56px;
    left: 0;
}
.ty-product-filters__block ul#ranges_ {
    float: left;
    height: 176px;
    overflow-x: scroll;
    padding-left: 10px;
   padding-bottom: 20px;
}
}
.filtt .ty-product-filters__block {
    border-bottom: 0px solid #ddd;
    margin-top:20px
}
.filtt h4 {
    margin-top: 28px;
    font-size: 19px;
    margin-bottom: -10px;
    text-align: center;
}
.filtt .ty-product-filters__block {
    border-bottom: 0px solid #ddd;
    margin-top:20px
}
.filtt h4 {
     margin-top: 28px;
    font-size: 19px;
    margin-bottom: -10px;
}
.ty-product-filters__color-filter {
    position: relative;
    overflow-y: hidden;
    line-height: 1.4em;
    margin-bottom: 7px;
    display: block;
    white-space: initial;
    overflow-x: scroll;
    width: 100%;
    padding-bottom: 10px;
}

.ty-product-filters__color-list-item {
    display: inline-block;
    padding: 0;
    margin: 3px;
    float: left;
}
.ty-product-filters__color-filter-swatch {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px rgb(0 0 0 / 30%);
}
.smaterial li.cm-product-filters-checkbox-container.ty-product-filters__group label {
    height: 30px;
    text-align: center;
    line-height: 30px;
    border: 2px solid;
    float: left;
    font-weight: 700;
    border-radius: 36px;
    padding: 0 7px;
    font-size: 12px;
    width: inherit;
}
.smaterial li.cm-product-filters-checkbox-container.ty-product-filters__group {
    float: left;
    margin: 5px 9px 0 0;
}
.sstoc li.cm-product-filters-checkbox-container.ty-product-filters__group label {
    height: 30px;
    text-align: center;
    line-height: 30px;
    border: 2px solid;
    float: left;
    font-weight: 700;
    border-radius: 36px;
    padding: 0 7px;
    font-size: 12px;
    width: inherit;
}
.sstoc li.cm-product-filters-checkbox-container.ty-product-filters__group {
    float: left;
    margin: 5px 9px 0 0;
}

.sstoc2 li.cm-product-filters-checkbox-container.ty-product-filters__group label {
    height: 30px;
    text-align: center;
    line-height: 30px;
    border: 2px solid;
    float: left;
    font-weight: 700;
    border-radius: 36px;
    padding: 0 7px;
    font-size: 12px;
    width: inherit;
}
.sstoc2 li.cm-product-filters-checkbox-container.ty-product-filters__group {
    float: left;
    margin: 5px 9px 0 0;
}

.sspret li.cm-product-filters-checkbox-container.ty-product-filters__group label {
    height: 30px;
    text-align: center;
    line-height: 30px;
    border: 2px solid;
    float: left;
    font-weight: 700;
    border-radius: 36px;
    padding: 0 7px;
    font-size: 12px;
    width: inherit;
}
.sspret li.cm-product-filters-checkbox-container.ty-product-filters__group {
    float: left;
    margin: 5px 9px 0 0;
}

.ty-product-filters__color-filter-item--disabled{
    opacity: 0.1;
}
