.cotitm img{
    width: 66px!important;
    margin-top: -14px;
}
.cotitm a {
    border: 1px solid #fff;
    margin: 5px 5px 5px 0;
    border-radius: 50%;
    width: 66px;
    height: 66px;
    display: block;
    float: left;
    overflow: hidden;
    margin-bottom: 3px;
}
.ty-wysiwyg-content h1.ty-product-block-title {
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
    word-wrap: break-word;
    width: 79%;
}
.imagez {
    width: 49%;
    float: left;
    margin-right: 1%;
    margin-bottom: 1.4%;
    max-height: 689px;
}

button#alegemar {
    position: absolute;
    background: #37a74b;
    padding: 19px 0 16px;
    width: 80.5%;
    margin-left: 0;
    margin-right: 16px;
    bottom: 0;
}
.collapses {
    border-top: 1px solid #f6f6f6;
}
.ty-list-price {
    color: #afafaf;
    line-height: 100%;
    font-size: 13px;
    display: inline-block;
    text-decoration: line-through;
}
span.red.ty-list-price.ty-nowrap {
    margin-left: 11px;
}
.tooltip1 {
    position: relative;
    display: inline-block;
}
.tooltip1 .tooltiptext {
    visibility: hidden;
    width: 240px;
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 5;
    bottom: -372%;
    padding: 10px;
    left: 0%;
    margin-left: -102px;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
}
.tooltip1 .tooltiptext::after {
    content: "";
    position: absolute;
    top: -28%;
    left: 41%;
    margin-left: 0px;
    border-width: 8px;
    border-style: solid;
    border-color: transparent transparent #FFFFFF transparent;
}
.tooltip1:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
.homepage-hotdeals .ty-add-to-wish {
    display: none;
}
.homepage-hotdeals .swiper-wrapper, .homepage-onsale .swiper-wrapper{
    padding-bottom:30px;
}
@media (min-width: 1224px){
    div#details-delivery, #details-return, #details-marimi {
        display: block!important;
    }
    .ty-product-block__price-actual .ty-price-num {
        font-size: 30px!important;
    }
    .ty-product-block__price-actual {
        margin: 10px 0px;
        display: inline-block;
    }
    .ty-product-block__left .ty-add-to-wish, .ty-product-block__left .ty-add-to-wish:hover {
        right: inherit!important;
        margin-left: -10px !important;
        position: relative!important;
        top: inherit!important;
        display: inline-block!important;
        border: 1px solid #ddd!important;
        border-radius: 0!important;
        height: 55px!important;
        width: 59px!important;
        margin-right: 0!important;
    }
    .ty-product-detail .ty-product-block__left {
        box-sizing: border-box;
        padding-right: 0!important;
        width: 30%!important;
        float: right;
        display: block;
    }
    .ty-product-options__radio--label {
        padding: 10px 14px!important;
    }
    span.list-price-label {
        display: none;
    }
    .tooltip1:hover .tooltiptext {
        visibility: visible;
        opacity: 1;
    }
    button.ty-btn__primary.ty-btn__big.ty-btn__add-to-cart.cm-form-dialog-closer.ty-btn {
        padding: 19px 0px 16px!important;
        margin-left: 0!important;
        margin-right: 16px!important;
        width: 80.5%!important;
    }
   
}
@media (max-width:768px) {
    .spp.pop .ty-product-block__left {
        padding: 0 15px;
    }
    .spp.pop .ty-product-block__left .ty-add-to-wish, .ty-product-block__left .ty-add-to-wish:hover {
        border-radius: 0;
        float: right;
        font-size: 1.12em;
        height: 43px;
        margin: 0 8px 0 0 !important;
        padding: 0;
        position: relative;
        right: auto;
        text-transform: uppercase;
        top: inherit;
        width: 50px;
        z-index: 3;
        border: 1px solid!Important;
        color: #000;
        visibility: visible;
        background: url(https://zappatos.ro/img/heart.png) 15px 12px no-repeat !important;
        background-size: auto;
        background-size: 18px !important;
        display: inline-block;
    }
    .spp.pop .ty-product-options__radio--label {
        display: inline-block;
        margin: 2px 5px 2px 0;
        border: 2px solid #edeff1;
        cursor: pointer;
        padding: 6px 10px;
        font-size: 19px;
        font-weight: 700;
      }
    .spp.pop  button.ty-btn__primary.ty-btn__big.ty-btn__add-to-cart {
        padding: 13px 0px 10px;
        width: 73.5%;
        background: #000;
        color: #fff;
    }

    .collapses {
        flex: 0 0 100%;
        padding: 0 10px 0 38px;
        border-top: 10px solid #f6f6f6;
        width: 112%;
        background: #fff;
        z-index: 3;
        margin: -10px 0 0 -31px;
        position: relative;
        border-bottom: 10px solid #f6f6f6;
        box-sizing: border-box;
    }
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, 0.125);
        border-radius: 0.25rem;
    }
    .collapses .card {
        border: 0;
        border-bottom: 0px solid #ebebeb;
        border-radius: 0;
    }
    .collapses .card-header {
        background-color: #fff;
        border: 0;
        font-weight: 700;
        padding: 16px 0;
        border-radius: 0;
    }
    .collapses .card .card-title {
        font-size: 1rem;
        line-height: 20px;
        font-weight: 400;
        display: flex;
        justify-content: space-between;
        position: relative;
    }
    .collapses .link-collapse {
        display: block;
        align-items: center;
        font-size: 14px;
        line-height: 16px;
        flex: 0 0 100%;
        padding-right: 24px;
        font-weight: 400;
        box-sizing: border-box;
    }
    .collapses .card .arrow-down-collapse {
        transition: 0.4s;
        display: flex;
        position: absolute;
        top: 0;
        right: 0;
    }
    .icon-size-5 {
        width: 20px;
        height: 20px;
    }
    .gray-800-color {
        color: #4a4a4a;
    }
    .collapses span.ty-icon-down-open {
        float: right;
        margin-right: -12px;
        margin-top: 2px;
        transform: rotate(180deg) translateY(0);
    }
    .colectie1 {
    overflow-x: scroll;
    height: 110px;
    }
    .cotitm {
    width: 70px;
    }
    .spp.pop .ty-product-block__price-actual .ty-price-num {
        font-size: 26px;
    }
    .spp.pop .ty-product-block__price-actual {
        margin: -1px 8px 0 0;
        display: inline-block;
      }
    .spp.pop .list-price-label {
        display: none;
      }
    .spp.pop span.red.ty-list-price.ty-nowrap {
        margin-left: 11px;
        margin-right: 6px;
    }
    .spp.pop .ty-product-block__option {
        margin-bottom: 10px;
    }
    .spp.pop .ty-product-options__radio--label {
        padding: 8px 14px;
    }
    .spp.pop .adaugaincos {
        margin-bottom: 20px;
      }
    .spp.pop button#alegemar {
        width: 77.8% !important;
        padding: 13px 0px 10px !important;
      }
      #details-marimi {
        padding: 10px 0px;
        border-top: 1px solid #f6f6f6;
        margin-top: 14px;
        width: 96%;
        line-height: 20px;
        overflow-x: scroll;
        display: block;
      }
      .spp.pop .colectie2.swiper-container {
        overflow-x: scroll;
      }
      #index .marimi {
        position: absolute;
        right: -10px;
        font-size: 12px;
        bottom: 5px;
    }
    .ty-product-detail .swiper {
        width: 100% !important;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 0;
    }
    .ty-product-block__left .colectie {
        display: block;
        position: relative;
        z-index: 0;
        padding: 0;
        width: 100%;
        margin: 0 auto;
        left: 0;
        right: 0;
        text-transform: none;
        font-size: 0.875rem;
        font-weight: 500;
        top: 0;
        background: #fff;
        padding: 5px 0;
        box-sizing: border-box;
        transform: translateY(0%);
        transition: transform 0.5s ease, opacity 0.2s ease;
        overflow: hidden;
    }
    #products .ty-wysiwyg-content h1.ty-product-block-title {
        margin-top: 2px!important;
    }
      
}
@media(min-width:410px) and (max-width:728px){
    #products .ty-product-detail .ty-product-block__left{
        margin-top: 419px!important;   
    }
}
.aplica_cup{
    vertical-align: bottom;
    margin-top: 10px;
    display: inline-block;
    border: 1px dashed #db3661;
    padding: 6px 12px;
    margin: -3px 8px -3px 10px;
    outline:0;
    color: #db3661;
    background: #fafafa;
    font-weight: 700;
    position: absolute;
}
.aplica_cup.app{
    margin: -3px 8px -3px -33px;
    width: 60%;
    text-align: center;
}
.promo_discount {
    background: #fdf4f5;
    border-radius: 0;
    display: none;
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 0;
    margin-left: 0;
    margin-top: 6px;
    padding: 12px 19px;
    position: relative;
    width: 101%;
    margin-left: -15px;
}
.promo_discount::after {
    content: "";
    position: absolute;
    top: -41%;
    left: 5%;
    margin-left: 0px;
    border-width: 12px;
    border-style: solid;
    border-color: transparent transparent #fdf4f5 transparent;
}
.promo_discount .price {
    font-weight: 700;
    color: #da3730;
    margin-right: 8px;
    font-size: 19px;
}
.promo_discount {
    display: block;
}
.delivz p {
    float: left;
    margin-right: 10px;
}
div#details-marimi {
    position: relative;
}
span.vrdim.dim1 {
    position: absolute;
    bottom: 2%;
    left: 35%;
}
span.vrdim.dim2 {
    position: absolute;
    right: 2%;
    top: 44%;
}
span.vrdim.dim3 {
    position: absolute;
    bottom: 11%;
    right: 12%;
}
@media(max-width:768px){
    span.vrdim.dim3 {
        right: 11%;
    }
}
.ty-list-price {
    color: #afafaf;
    line-height: 100%;
    font-size: 13px;
    display: inline-block;
    text-decoration: line-through;
}

    .tooltip1 {
  position: relative;
  display: inline-block;
  
}
.tooltip1 .tooltiptext {
    visibility: hidden;
    width: 240px;
    background-color: #fff;
    color: #333;
    text-align: left;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 5;
    bottom: -372%;
    padding: 10px;
    left: 0%;
    margin-left: -102px;
    opacity: 0;
    transition: opacity 0.3s;
    box-shadow: 0px 0px 15px rgb(0 0 0 / 15%);
}